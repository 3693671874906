import React from 'react'
import styled from 'styled-components'
import H2 from '../H2'
import H4 from '../H4'
import SearchBar from './SearchBar'
import GlossaryLink from './GlossaryLink'

const NeedHelpBlock = () => {
  return (
    <OuterContainer>
      <Container>
        <TextContainer>
          <StyledH2>HAI BISOGNO DI AIUTO?</StyledH2>
          <H4>Se non hai trovato quello che cerchi puoi consultare questa sezione</H4>
        </TextContainer>
        <SearchbarGlossaryContainer>
          <ContainerWrapper>
            <OuterBlockContainer>
              <BlockContainer>
                <H4>
                  Cerca tra le domande più frequenti
                </H4>
                <SearchBar/>
              </BlockContainer>
            </OuterBlockContainer>
          </ContainerWrapper>
          <ContainerWrapper>
            <OuterBlockContainer>
              <BlockContainer>
                <H4>
                  Scopri con noi il significato di alcuni termini dell’economia
                </H4>
                <GlossaryLink/>
              </BlockContainer>
            </OuterBlockContainer>
          </ContainerWrapper>
        </SearchbarGlossaryContainer>
      </Container>
    </OuterContainer>
  )
}

// region Style
const OuterContainer = styled.div`
  border-top: 1px solid #D8D8D8;
  padding-bottom: 3rem;
  @media (min-width: 993px) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const Container = styled.div`
  max-width: 1140px!important;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const TextContainer = styled.div`
  margin-top: -2.4rem;
  margin-bottom: 4rem;
`
const StyledH2 = styled(H2)`
  color: #FCBB00;
  background-color: #F5F5F5;
  padding: 0.5rem 3rem;
  display: inline-block;
  margin-bottom: 0;
`
const SearchbarGlossaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const ContainerWrapper = styled.div`
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 3rem;
`
const OuterBlockContainer = styled.div`
  background-color: white;
  height: 100%;
  text-align: center;
  padding: 2rem 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
`
const BlockContainer = styled.div`
  padding: 5rem 1.5rem;
  position: relative;
  width: 100%;
  max-width: 50rem;
  display: inline-block;
  text-align: center;
`
// endregion

export default NeedHelpBlock
