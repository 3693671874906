import React from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import H1 from '../../commons/H1'
import H2 from '../../commons/H2'
import H3 from '../../commons/H3'
import P from '../../commons/P'
import Link from '../../commons/Link'
import mainBackground from '../../../img/bg_intro.jpg'
import outerBackground from '../../../img/bg_about.jpg'
import {useNavigate} from 'react-router-dom'

const EmergenzaCOVID19 = () => {
  const navigate = useNavigate()

  return(
    <>
      <Header/>
      <Main>
        <OuterContainer>
          <InnerContainer>
            <CovidLead>
              <StyledH1>EMERGENZA COVID-19</StyledH1>
              <StyledH3>Informazioni per la Clientela</StyledH3>
              <Info>
                <StyledP style={{marginBottom: 0}}>
                  Il <strong>team QuintoPuoi di Banca Sistema S.p.A.</strong> continua ad operare attraverso una gestione delle attività da remoto, nel rispetto dei decreti emanati dal Governo per il contenimento dell’emergenza COVID-19.
                </StyledP>
              </Info>
            </CovidLead>
            <MainContainer>
              <Wrapper>
                <CommunicationContainer>
                  <H2>COMUNICAZIONI IN FORMATO DIGITALE</H2>
                  <div>
                    <P>Nell’ambito dell’emergenza sanitaria, Banca d’Italia ha incentivato l’invio delle comunicazioni alla Clientela in formato digitale, in quanto la corrispondenza nel tradizionale formato cartaceo avrebbe potuto subire ritardi nella consegna.</P>
                    <P>Il Cliente potrà fruire di tutte le comunicazioni (es. rendicontazione periodica, comunicazioni di servizio, etc.) in formato digitale, semplicemente inviando una e-mail all’indirizzo <A href="mailto:assistenzaclienti@quintopuoi.it">assistenzaclienti@quintopuoi.it</A> e indicando:</P>
                    <Ul>
                      <li>Nome e cognome dell’intestatario del contratto</li>
                      <li>Numero del contratto</li>
                      <li>Indirizzo e-mail a cui ricevere le comunicazioni</li>
                    </Ul>
                    <P>È inoltre necessario allegare alla e-mail il seguente modulo compilato e firmato unitamente ad una copia fronte e retro di un documento di riconoscimento in corso di validità.</P>
                    <P>
                      <Link link={`${process.env.PUBLIC_URL}/uploads/QP_Richiesta_Comunicazioni_Digitali.pdf`} text={'SCARICA MODULO'}/>
                    </P>
                    <P>&nbsp;</P>
                    <H2>DOMANDE E RISPOSTE FREQUENTI IN TEMA COVID-19</H2>
                    <P>Il <strong>team QuintoPuoi</strong> ha raccolto in questa sezione le FAQ più rilevanti utili a chiarire a tutti i Clienti i principali temi legati all’operatività della cessione del quinto durante l’emergenza COVID-19.<br/>
                      Si ricorda altresì che al seguente link <A href="http://www.mef.gov.it/covid-19/faq.html">http://www.mef.gov.it/covid-19/faq.html</A> è possibile consultare le risposte predisposte dal Ministero dell’Economia e delle Finanze alle domande più frequenti in merito ai provvedimenti economici assunti dal Governo per contrastare l’emergenza derivante dall’epidemia COVID-19.</P>
                  </div>
                </CommunicationContainer>
                <Square>
                  <SquareTitle>
                    Posso richiedere la sospensione della rata relativa alla cessione del quinto dello stipendio/pensione o delegazione di pagamento?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>No. Il decreto legge “Cura Italia” del 17 marzo 2020, all’art. 54, prevede espressamente che la sospensione dei pagamenti operi esclusivamente in relazione ai mutui prima casa, e non agli altri finanziamenti erogati dalle Banche. Pertanto, solo i titolari di un contratto di mutuo per l’acquisto della prima casa possono sospendere il pagamento delle rate, fino a diciotto mesi, al verificarsi di specifiche condizioni destinate ad incidere negativamente sul reddito complessivo del nucleo familiare. Analoga posizione è espressa peraltro nell’accordo ABI-associazioni dei consumatori del 23 aprile u.s. che esclude, fra le altre, le operazioni di credito verso la cessione del quinto dello stipendio o della pensione e le delegazioni di pagamento dalla possibilità di sospendere il pagamento della quota capitale.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    Il mio datore di lavoro ha fatto accesso alla procedura CIG COVID-19: per la durata della CIG, devo pagare personalmente le rate del finanziamento?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>Se il datore di lavoro ha attivato la cassa integrazione guadagni (CIG), le rate potranno essere riconsiderate in applicazione dell’art. 35 dpr 180/1950 che riporta quanto segue: “con riduzione superiore al terzo, la trattenuta non può eccedere il quinto dello stipendio ridotto”. Il datore di lavoro dovrà fornire comunicazione scritta di attivazione della CIG affinché il team QuintoPuoi, previa verifica della copertura assicurativa abbinata al contratto di finanziamento, possa eventualmente autorizzare la diminuzione e/o l’accodamento delle rate.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    In che modo il mio datore di lavoro può contattare il team QuintoPuoi per comunicare l’accesso alla procedura CIG COVID-19?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <P>Il datore di lavoro dovrà inviare specifica comunicazione al team QuintoPuoi, all’indirizzo email sinistri_cqs@bancasistema.it per tutte le verifiche e gli adempimenti del caso. La mail dovrà contenere:</P>
                  </SquareSubtitleContainer>
                  <ul style={{listStyle: 'square', padding: 0}}>
                    <li><strong>i nominativi e CF dei dipendenti titolari dei contratti di cessione del quinto o delegazione di pagamento</strong>;</li>
                    <li><strong>tutti gli estremi utili per ricontattare il datore di lavoro per accertamenti e riscontri</strong>.</li>
                  </ul>
                </Square>
                <Square>
                  <SquareTitle>
                    Cosa devo fare per comunicare il decesso del titolare di un finanziamento di cessione del quinto?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>In caso di decesso del titolare del finanziamento di cessione del quinto è necessario inviare, tramite mail, il certificato di morte con scansione dell’ORIGINALE al seguente indirizzo <A href="mailto:sinistri_cqs@bancasistema.it">sinistri_cqs@bancasistema.it</A> indicando contestualmente il nome e cognome dell’intestatario del contratto oltre ai documenti in corso di validità di chi scrive la mail evidenziando il grado di parentela.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    A chi posso richiedere informazioni sulle coperture assicurative incluse nel mio finanziamento?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>Per qualsiasi approfondimento sulla polizza assicurativa si consiglia di inviare una email a <A href="mailto:sinistri_cqs@bancasistema.it">sinistri_cqs@bancasistema.it</A>.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    Nel caso di perdita di lavoro o interruzione del rapporto di lavoro, a chi mi devo rivolgere?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>In caso di interruzione del rapporto di lavoro, è necessario fornire un documento comprovante tale evento (es. lettera di licenziamento, lettera di dimissioni, cedolino dell’ultima busta paga) inviando una mail a <A href="mailto:sinistri_cqs@bancasistema.it">sinistri_cqs@bancasistema.it</A> oppure tramite PEC a <A href="mailto:cqs.bancasistema@legalmail.it">cqs.bancasistema@legalmail.it</A> indicando sempre il numero di contratto ed allegando un documento di identità in corso di validità.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    Come posso contattare il team QuintoPuoi di Banca Sistema durante l’emergenza COVID-19?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <LastChildP>Il team QuintoPuoi di Banca Sistema è a disposizione della Clientela ai contatti telefonici ed email indicati in questa pagina.</LastChildP>
                  </SquareSubtitleContainer>
                </Square>
                <Square>
                  <SquareTitle>
                    Posso fare una richiesta di finanziamento tramite cessione del quinto dello stipendio/pensione o delegazione di pagamento anche senza recarmi presso la vostra sede o agenzia?
                  </SquareTitle>
                  <SquareSubtitleContainer>
                    <P>Sì, la maggior parte delle operazioni può essere svolta da remoto. Pertanto, invitiamo i Clienti a prendere contatti con il team QuintoPuoi di Banca Sistema per poter ricevere tutte le indicazioni necessarie.<br/>
                      Questi i principali canali di contatto:</P>
                    <ul style={{listStyle: 'square', padding: 0}}>
                      <li><strong>direttamente da questo sito, compilando gratuitamente il form di richiesta finanziamento <A onClick={() => navigate('/preventivatore')}>https://www.quintopuoi.it/preventivatore/</A></strong>;</li>
                      <li><strong>scrivendo una mail di richiesta a <A href="mailto:clienticqs@bancasistema.it">clienticqs@bancasistema.it</A></strong>;</li>
                      <li><strong>chiamando il numero verde 800 691 629</strong>.</li>
                    </ul>
                  </SquareSubtitleContainer>
                </Square>
                <StyledH2 style={{marginTop: '3rem'}}>IL TEAM QUINTOPUOI ACCANTO AI SUOI CLIENTI</StyledH2>
                <div style={{marginBottom: '1rem'}}>
                  <LastChildP>In linea con le disposizioni ministeriali e a tutela della salute di tutti, il team QuintoPuoi di Banca Sistema ha messo a disposizione di tutti i Clienti:</LastChildP>
                </div>
                <StyledText>Assistenza telefonica dedicata </StyledText>
                <div>
                  <LastChildP>
                    Numero verde <strong>800 691 629</strong>, attivo dal lunedì al venerdì, dalle <strong>8.30 alle 18.00</strong>.<br/>
                    Il team QuintoPuoi assisterà tempestivamente ed in maniera esaustiva tutti i Clienti, affinché siano correttamente informati sullo scenario attuale e possano prendere decisioni consapevoli e coerenti con le loro esigenze.
                  </LastChildP>
                </div>
                <StyledText>Indirizzo mail dedicato</StyledText>
                <div>
                  <LastChildP>
                    <A href="mailto:Incassi.cqs@bancasistema.it">Incassi.cqs@bancasistema.it</A> &nbsp;
                    è l’indirizzo a cui i Clienti potranno inviare tutte le richieste di chiarimento sul proprio finanziamento o presentare esigenze specifiche. Sarà premura del team QuintoPuoi esaminare le richieste pervenute e fornire un riscontro nel più breve tempo possibile.
                  </LastChildP>
                </div>
                <LastBox>
                  <LastChildP style={{fontWeight: 'bold', textAlign: 'center'}}>Per conoscere le misure di sostegno al finanziamento che Banca Sistema ha attivato per i suoi Clienti è possibile cliccare sul seguente link <A href="https://bancasistema.it/covid-informazioni-clientela">https://bancasistema.it/covid-informazioni-clientela</A>.</LastChildP>
                </LastBox>
              </Wrapper>
            </MainContainer>
          </InnerContainer>
        </OuterContainer>
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
 @media(max-width: 990px) {
    margin-top: 70px;
  }
  margin-top: 128px;
  opacity: 1;
  background-image: url(${mainBackground});
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const OuterContainer = styled.div`
  media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  position: relative;
  padding-top: 7rem;
  background-image: url(${outerBackground});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`
const InnerContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const CovidLead = styled.div`
  @media (min-width: 993px){
    padding: 4rem 5rem;
  }
  background: #fff;
  border: #FCBB00 3px solid;
  border-left: none;
  border-right: none;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem;
`
const StyledH1 = styled(H1)`
  margin-bottom: 0.5rem;
  letter-spacing: 3px;
  font-size: 3.5rem;
`
const StyledH3 = styled(H3)`
  color: #FCBB00;
  margin-bottom: 1.5rem;
`
const Info = styled.div`
  color: #AEAEAE;
  letter-spacing: 0;
  font-weight: bold;
`
const StyledP = styled(P)`
  font-weight: bold;
`
const MainContainer = styled.div`
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Wrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const CommunicationContainer = styled.div`
  text-align: initial;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`
const A = styled.a`
  :hover {
    color: #000;
  };
  cursor: pointer;
  color: #FCBB00;
  font-weight: bold;
  transition: all .3s ease-in-out;
`
const Ul = styled.ul`
  list-style: disc;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem !important;
`
const Square = styled.div`
  text-align: initial;
  background: #fff;
  border-left: #FCBB00 4px solid;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
`
const SquareTitle= styled.div`
  font-weight: bold;
  text-transform: uppercase;
`
const SquareSubtitleContainer = styled.div`
  margin-top: 1rem;
`
const LastChildP = styled(P)`
  text-align: initial;
  margin-bottom: 0
`
const StyledH2 = styled(H2)`
  margin-top: 3rem;
  text-align: initial;
`
const StyledText = styled.div`
  text-align: initial;
  font-size: 2rem;
  color: #FCBB00;
  margin: 2rem 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
`
const LastBox = styled.div`
  background: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 3rem 5rem;
  margin-top: 4rem;
`
// endregion

export default EmergenzaCOVID19
