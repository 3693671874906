import React  from "react"
import styled from "styled-components"
import Header from "../../commons/Header"
import Footer from "../../commons/footer/Footer"
import TitleSection from "../../commons/TitleSection"
import H4 from "../../commons/H4"
import H2 from "../../commons/H2"
import P from "../../commons/P"
import headerBackground from "../../../img/CHI_SIAMO.jpg"
import mobileHeaderBackground from "../../../img/chi-mobile.jpg"
import mainBackground from "../../../img/bg_intro.jpg"
import aboutBackground from "../../../img/bg_about.jpg"
import Form from "../../commons/Form"
import H1 from '../../commons/H1'
import FAQs from '../assistenza/FAQs'

const FormText = () => {
  return (
    <>
      <StyledH1>Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em></StyledH1>
      <FormStyledP>Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo
        indeterminato da aziende SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici</FormStyledP>
    </>
  )
}
const ChiSiamo = () => {
  return (
    <>
      <Header />
      <TitleSection
        backgroundUrl={`url(${headerBackground})`}
        mobileBackgroundUrl={`url(${mobileHeaderBackground})`}
        title={"CHI SIAMO"}
        subtitle={"Banca Sistema, una realtà indipendente specializzata"}
      />
      <Main>
        <AboutContainer>
          <div style={{ marginTop: "-150px" }}>
            <Form windowOffset={500}>
              <FormText/>
            </Form>
          </div>
          <Container>
            <Row>
              <RowInnerContainer>
                <StyledH2>
                  TRASFORMIAMO L’IDEA DI BANCA IN UN SERVIZIO SU MISURA
                </StyledH2>
                <StyledP>
                  QuintoPuoi è il nuovo servizio di{" "}
                  <strong>Banca Sistema</strong>,{" "}
                  <strong>realtà finanziaria indipendente&nbsp;</strong>
                  nata nel 2011,{" "}
                  <strong>
                    quotata sul segmento STAR di Borsa Italiana&nbsp;
                  </strong>
                  dal 2015 e cresciuta nel corso degli anni. Siamo partiti come
                  istituto specializzato nell’acquisto di crediti commerciali
                  vantati nei confronti della Pubblica Amministrazione,
                  garantendo liquidità alle imprese sue fornitrici.
                </StyledP>
                <StyledP>
                  Ci siamo diversificati e, unendo le credenziali di una solida
                  banca quotata con i vantaggi di uno{" "}
                  <strong>
                    specialista sempre pronto ad innovare e a crescere
                  </strong>
                  , oggi siamo in grado di offrire servizi, non solo nel{" "}
                  <strong>factoring </strong>e nella gestione e{" "}
                  <strong>recupero del credito</strong>, ma anche nel{" "}
                  <strong>banking</strong>, nel{" "}
                  <strong>credito su pegno&nbsp;</strong>e nella{" "}
                  <strong>cessione del quinto</strong>.
                </StyledP>
                <StyledP>
                  Nell’ambito dell’erogazione di prestiti personali sotto forma
                  della cessione del quinto dello stipendio e della pensione
                  siamo attivi dal 2014 attraverso l’acquisto di crediti
                  generati da altri operatori. Dal 30 giugno 2019 abbiamo
                  iniziato ad operare anche nell’origination diretta offrendo{" "}
                  <strong>
                    un nuovo servizio finanziario specializzato: QuintoPuoi
                  </strong>
                  , nato dall’acquisizione e successiva fusione per
                  incorporazione di Atlantide S.p.A. in Banca Sistema.
                </StyledP>
                <StyledP>&nbsp;</StyledP>
                <StyledH2>
                  <strong>&nbsp;I NOSTRI ELEMENTI DISTINTIVI</strong>
                </StyledH2>
                <Row>
                  <RowElement>
                    <StyledH4>TRASPARENZA</StyledH4>
                    <LastChildP>
                      la centralità del codice etico nel vissuto lavorativo
                      quotidiano è la base di un ambiente in cui si respira il
                      rispetto delle persone, la correttezza e la trasparenza
                      nei confronti dei singoli e della collettività
                    </LastChildP>
                  </RowElement>
                  <RowElement>
                    <StyledH4>SPECIALIZZAZIONE</StyledH4>
                    <LastChildP>
                      essere una realtà finanziaria specializzata significa
                      garantire ai nostri interlocutori e clienti un team di
                      professionisti coeso, offerte personalizzate e servizi
                      innovativi
                    </LastChildP>
                  </RowElement>
                  <RowElement>
                    <StyledH4>VALORE DELLA RELAZIONE</StyledH4>
                    <LastChildP>
                      disponibilità, concretezza e attenzione alle specifiche
                      esigenze della clientela, unitamente alla nostra
                      specializzazione, sono humus per relazioni di valore,
                      solide e durature
                    </LastChildP>
                  </RowElement>
                  <RowElement>
                    <StyledH4>INNOVAZIONE</StyledH4>
                    <LastChildP>
                      un’azienda giovane e dinamica sempre pronta ad innovare e
                      a crescere, dove le persone sono protagoniste del progetto
                      di crescita con la loro professionalità e il lavoro di
                      squadra
                    </LastChildP>
                  </RowElement>
                  <RowElement>
                    <StyledH4>ECCELLENZA</StyledH4>
                    <LastChildP>
                      puntiamo alla massima efficienza nel dare assistenza e
                      risposte adeguate ai nostri clienti e mettiamo a
                      disposizione personale competente, pronto ad ascoltare e a
                      trovare soluzioni
                    </LastChildP>
                  </RowElement>
                  <RowElement>
                    <StyledH4>PRESENZA TERRITORIALE</StyledH4>
                    <LastChildP>
                      oltre che online, siamo presenti su tutto il territorio
                      nazionale con le nostre sedi, filiali, sportelli ed una
                      rete di agenzie assistite da personale commerciale
                      specializzato
                    </LastChildP>
                  </RowElement>
                </Row>
              </RowInnerContainer>
            </Row>
          </Container>
        </AboutContainer>
      </Main>
      <FAQsContainer style={{}}>
        <H2 style={{textAlign: 'center'}}>
          FAQ
        </H2>
        <FAQs/>
      </FAQsContainer>
      <Footer />
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const AboutContainer = styled.div`
  position: relative;
  padding-top: 7rem;
  background-image: url(${aboutBackground});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const RowInnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 100px;
`
const StyledH2 = styled(H2)`
  text-align: initial;
`
const FAQsContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  padding: 3rem 0;
`
const StyledP = styled(P)`
  text-align: initial;
`
const LastChildP = styled(StyledP)`
  margin-bottom: 0;
`
const RowElement = styled.div`
  margin-bottom: 3rem !important;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 768px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH4 = styled(H4)`
  text-align: initial;
  color: #fcbb00;
`
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const FormStyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
// endregion

export default ChiSiamo
