import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import magnifier from '../../../img/ico_magnifier.svg'


const SearchBar = () => {
  const [value, setValue] = useState('')
  const navigate = useNavigate()
  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    navigate(`/assistenza?ricerca=${encodeURIComponent(value)}#faqs`)
  }
  return (
    <form onSubmit={onSubmit}>
      <Input type="search" name="ricerca" value={value} onChange={(e) => setValue(e.target.value)} placeholder="Inserisci l’argomento della ricerca"/>
    </form>
  )
}

const Input = styled.input`
  ::placeholder{
    color: #969696;
  }
  background-color: #F5F5F5;
  box-shadow: none;
  margin: auto;
   max-width: 32rem;
  display: inline-block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  font-weight: 400;
  padding-left: 5.4rem;
  padding-right: 2rem;
  background-image: url(${magnifier});
  background-position: 2rem center;
  background-repeat: no-repeat;
  background-size: 2rem;

  display: block;
  width: 100%;
  height: 4.6rem;
  border-radius: 2.3rem;
  color: #222;
  font-size: 1.3rem;
  border: 1px solid #D8D8D8;
  letter-spacing: .5px;
`

export default SearchBar
