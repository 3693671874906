import React from 'react'
import styled from 'styled-components'
import Link from '../commons/Link'
import H2 from '../commons/H2'
import H4 from '../commons/H4'
import P from '../commons/P'
import CustomHTML from '../commons/CustomHTML'

const FinancingExample = ({data}: any) => {
  return (
    <Boxcon>
      <BoxconInner>
        <H2>UN ESEMPIO DI FINANZIAMENTO</H2>
        <Row>
          <RowText>
            <H2Text>OTTIENI</H2Text>
            <Amount>€ {data?.amount}</Amount>
          </RowText>
          <Arrow />
          <FeeContainer>
            <Badge>
              <div>
                <Small>con</Small>
                <Span>
                  <Sub>€</Sub>{data?.installment}
                </Span>
                <Small>/mese</Small>
              </div>
            </Badge>
          </FeeContainer>
          <Arrow />
          <RowText>
            <H2Text>{data?.installmentNumber}</H2Text>
            <H2Text>{data?.tan}</H2Text>
            <H2Text>{data?.taeg}</H2Text>
          </RowText>
        </Row>
        <PromoMessageContainer>
          <PromoMessageInnerContainer>
            <Header>
              <StyledH4>{data?.disclaimerTitle}</StyledH4>
            </Header>
            <TextSection>
              <div>
                <P style={{ fontSize: '1.2rem' }}></P>
                <HTMLContainer>
                 <CustomHTML html={data?.disclaimerText}/>
                </HTMLContainer>
                <P style={{ fontSize: '1.2rem' }}></P>
              </div>
            </TextSection>
          </PromoMessageInnerContainer>
        </PromoMessageContainer>
        <LinkContainer>
          <LinkInnerContainer>
            <Link text={'CHIEDI SUBITO'} link={'#preventivatore2'} linkStyle={{ minWidth: 'unset' }} />
          </LinkInnerContainer>
        </LinkContainer>
      </BoxconInner>
    </Boxcon>
  )
}

// region Style
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0;
`
const BoxconInner = styled.div`
  @media (min-width: 769px) {
    padding: 5rem;
  }
  padding: 1.5rem;
`
const Row = styled.div`
  align-items: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const RowText = styled.div`
  @media (min-width: 993px) {
    text-align: left;
  }
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  text-align: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const H2Text = styled.div`
  line-height: 1.2;
  font-weight: 700;
  font-size: 2.5rem;
`
const Amount = styled.div`
  font-size: 5rem;
  font-weight: 700;
  color: #eaa713;
`
const Arrow = styled.div`
  :after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: dashed 2px #ccc;
    line-height: 1px;
  }
  @media (min-width: 993px) {
    display: block;
  }
  @media (min-width: 992px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  display: none;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const FeeContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  display: flex !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Badge = styled.div`
  width: 17rem;
  height: 17rem;
  text-align: center;
  margin: 0 auto 4rem;
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
const Small = styled.small`
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  width: 100%;
`
const Span = styled.span`
  font-size: 5rem;
`
const Sub = styled.sub`
  vertical-align: inherit;
  font-size: 2.4rem;
`
const PromoMessageContainer = styled.div`
  margin-bottom: 3rem !important;
`
const PromoMessageInnerContainer = styled.div`
  background: #f5f5f5 !important;
  position: relative;
  width: 100%;
  background-color: #fff;
  border-left: 5px solid #fcbb00;
  text-align: left;
`
const Header = styled.div`
  min-height: 4rem;
  padding: 1rem;
  display: flex;
`
const StyledH4 = styled(H4)`
  font-size: 1.3rem;
  margin-bottom: 0;
  color: #222;
  font-weight: 700;
`
const TextSection = styled.div`
  display: block;
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
`
const HTMLContainer = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1em;
`
const LinkContainer = styled.div`
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const LinkInnerContainer = styled.div`
  text-align: center !important;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
// endregion

export default FinancingExample
