import React, { useState } from 'react'
import styled from 'styled-components'
import CustomHTML from '../../commons/CustomHTML';

const ExamplesLanding = ({data}: any) => {
  const [hovering, setHovering] = useState(1)

  return (
    <Container>
      <Row>
        {data.map((feData: any, index: number) => {
          return (
            <Col
              key={index}
              className={hovering === index ? 'is-highlighted' : ''}
              onMouseOver={() => {
                setHovering(index)
              }}
              onMouseOut={() => {
                setHovering(1)
              }}>
              <Card>
                <div>
                  <Title className={'title-card'}>{feData?.title}</Title>
                  <h1>
                    <small style={{ textTransform: 'uppercase' }}>Ottieni</small> € {feData?.amount}
                  </h1>
                  <AmountPerMonth className={'amount-per-month'}>
                    <div>
                      <small>con</small>
                      <p style={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
                        <small>€</small>
                        {feData?.installment}
                      </p>
                      <small>/mese</small>
                    </div>
                  </AmountPerMonth>
                  <OptionsList>
                    <li>{feData?.installmentNumber}</li>
                    <li>{feData?.tan}</li>
                    <li>{feData?.taeg}</li>
                  </OptionsList>
                  <AskSoonButton href="#preventivatore2">
                    <span>CHIEDI SUBITO</span>
                  </AskSoonButton>
                </div>
              </Card>
              <TextContainer>
                <div>
                  <header>
                    <div>
                      <h4>{feData?.disclaimerTitle}</h4>
                    </div>
                  </header>
                  <div>
                    <section>
                      <CustomHTML html={feData?.disclaimerText}/>
                    </section>
                  </div>
                </div>
              </TextContainer>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default ExamplesLanding

// region style

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10rem;
`

const Row = styled.div`
  @media (max-width: 992px) {
    padding-top: 5rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const Col = styled.div`
  @media (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem !important;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  &:hover {
    & > article {
      transform: scale(1.1);
      background-color: #f5f5f5;
      box-shadow: 0 0 20px 0 rgb(0 0 0 / 25%);
    }
    & > amount-per-month {
      background: #ffb800;
    }

    & > #title-card {
      color: #ffbb00;
    }
    & a {
      background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
    }
  }
`

const Card = styled.article`
  background: #e5e5e5;
  border-radius: 3.2rem;
  position: relative;
  width: 100%;
  padding: 5rem 3rem 5rem;
  z-index: 1;
  transition: all 0.1s ease-out;
  & h1 {
    color: #2e2e2e;
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0.75em;
    & small {
      font-size: 1.3rem;
      display: block;
      font-weight: 400;
    }
  }
`

const TextContainer = styled.div`
  @media (min-width: 100px) and (max-width: 768px) {
    margin-top: 1rem;
  }
  width: 100%;
  margin-top: 4rem;
  & > div {
    background-color: #f5f5f5;
    position: relative;
    width: 100%;
    border-left: 5px solid #fcbb00;
  }
  & header {
    min-height: 4rem;
    padding: 1rem;
    display: flex;
    text-align: start;
  }
  & h4 {
    font-size: 1.3rem;
    color: #222;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.25px;
  }
  & h4:last-child {
    margin-bottom: 0;
  }
  & section {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f5f5f5;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: start;
    & p {
      font-weight: 400;
      line-height: 1.5;
      font-size: 1.2rem;
      margin-bottom: 1em;
    }
  }
`

const Title = styled.h3`
  font-size: 2rem;
  letter-spacing: 0.5px;
  color: #404040;
  height: 2.4em;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.75em;
`

const AmountPerMonth = styled.div`
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
  background-color: #fff;
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  & p {
    font-size: 1.8rem;
    display: block;
    width: 100%;
    font-weight: 400;
  }
  & sub {
    vertical-align: inherit;
    font-size: 2.4rem;
  }
`

const OptionsList = styled.ul`
  list-style: none;
  margin-bottom: 4rem;
  transition: all 0.1s ease-out;
  & > li {
    border-bottom: 1px dashed #999;
    font-size: 2.3rem;
    font-weight: 700;
    color: #aeaeae;
    line-height: 2.3;
    transition: all 0.1s ease-out;
  }
`

const AskSoonButton = styled.a`
  color: inherit;
  background: #393939;
  width: 100%;
  display: flex;
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  letter-spacing: 1px;
  & span {
    padding-top: 0.25em;
    transition: all 0.1s ease-out;
    color: #222;
    white-space: nowrap;
  }
`

// endregion
