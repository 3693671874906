import React from 'react'
import styled from 'styled-components'
import H1 from '../commons/H1'
import P from '../commons/P'

const LandingProjectsBlock = () => {
  return (
    <Boxcon>
      <BoxconInner>
        <StyledH1>DAI FORMA AI TUOI PROGETTI CON <Span>QuintoPuoi</Span></StyledH1>
        <StyledP>
          Scopri QuintoPuoi, il prestito rimborsabile attraverso la cessione del quinto dello stipendio o della pensione.<br/>
          Restituisci la somma ottenuta attraverso rate mensili comodamente trattenute dal tuo cedolino.<br/>
          L’importo delle rate mensili non può superare 1/5 dello stipendio o della pensione e puoi scegliere il numero di rate in cui dilazionare il prestito, da 24 rate fino a un massimo di 120.
        </StyledP>
        <StyledP style={{marginBottom: 0, paddingBottom: '3rem'}}>QuintoPuoi è un prodotto di Banca Sistema S.p.A.</StyledP>
      </BoxconInner>
    </Boxcon>
  )
}

// region Style
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0;
`
const BoxconInner = styled.div`
  @media (min-width: 769px) {
    padding: 5rem;
  }
  text-align: initial;
  padding: 1.5rem;
`
const StyledH1 = styled(H1)`
  margin-bottom: 0.75em;
  color: #414141;
  font-size: 28px;
  text-transform: uppercase;
`
const Span = styled.span`
  color: #EAA713;
`
const StyledP = styled(P)`
  color: #787D84;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
`
// endregion

export default LandingProjectsBlock
