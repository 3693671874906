import React from 'react'
import styled from 'styled-components'
import H1 from '../../commons/H1'
import P from '../../commons/P'
import Bold from '../../commons/Bold'

const HowToRequire = () => {
  return (
    <Boxcon>
      <BoxconInner>
        <StyledH1>COME RICHIEDERE UN PREVENTIVO PER <Span>PrestitoPuoi</Span></StyledH1>
        <StyledP>
          Grazie a Banca Sistema puoi richiedere un <Bold>preventivo gratuito</Bold> <Span>PRESTITOPUOI</Span> in modo facile e intuitivo.
          Ti basterà inserire alcune informazioni nel <Bold>Form online</Bold> per essere rapidamente contattato dai nostri consulenti e ricevere un <Bold>preventivo personalizzato</Bold> sulla base delle tue esigenze.
        </StyledP>
        <StyledP>
          La <Bold>compilazione del Form</Bold> per la richiesta di preventivo è molto semplice e richiede l’inserimento di <Bold>alcuni campi necessari</Bold>.
          Nel primo dovrai specificare in modo generico la tua <Bold>posizione lavorativa</Bold>: sei un dipendente pubblico?
          Un privato? Un pensionato? Nei due campi restanti invece ti verrà richiesto di inserire <Bold>l’importo desiderato del finanziamento</Bold> e la <Bold>durata</Bold> che vorresti sostenere.
          Tra i campi non obbligatori puoi anche inserire la <Bold>finalità ultima della tua richiesta</Bold>, ovvero la motivazione per la quale richiedi la tua liquidità.
        </StyledP>
        <StyledP>
          Nella seconda parte del Form, invece, è necessario inserire i tuoi <Bold>dati personali</Bold>.
          Nello specifico ti verranno richiesti i tuoi <Bold>dati anagrafici</Bold> (vale a dire nome, cognome, anno di nascita e località di residenza) e i <Bold>tuoi contatti</Bold>, ovvero il tuo <Bold>numero di telefono</Bold> e la tua <Bold>e-mail</Bold>.
          Con questi dati saremo in grado di contattarti per proporti la migliore soluzione di finanziamento su misura per te.
        </StyledP>
        <StyledP style={{marginBottom: 0, fontWeight: 'bold'}}>Scegli <Span>PRESTITOPUOI</Span>, scegli il credito alla famiglia di Banca Sistema.</StyledP>
      </BoxconInner>
    </Boxcon>
  )
}

// region Style
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0 1rem 0;
`
const BoxconInner = styled.div`
  @media (min-width: 769px) {
    padding: 5rem;
  }
  text-align: initial;
  padding: 1.5rem;
`
const StyledH1 = styled(H1)`
  margin-bottom: 0.75em;
  color: #414141;
  font-size: 28px;
  text-transform: uppercase;
`
const Span = styled.span`
  font-weight: bold;
  color: #EAA713;
`
const StyledP = styled(P)`
  color: #787D84;
  font-size: 18px;
  letter-spacing: 0;
`
// endregion

export default HowToRequire
