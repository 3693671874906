import React, {useEffect, useState} from 'react'
import Header from "../../commons/Header"
import Hero from "../../commons/Hero"
import arrowSvg from "../../../images/freccia.svg"
import { useMediaQuery } from 'react-responsive'
import iconTfs from "../../../images/icon-tfs.svg"
import styled from "styled-components"
import Form from "../../commons/Form"
import VideoPlayer from "../../commons/VideoPlayer"
import IntroDocument from "./IntroDocument"
import Examples from "./Examples"
import TfsBox from "./TfsBox"
import BSJumbo from "./BSJumbo"
import Footer from "../../commons/footer/Footer"
import {Link, useLocation} from 'react-router-dom'
import H1 from '../../commons/H1'
import P from '../../commons/P'
import ExamplesSlider from './examplesSlider/ExamplesSlider'
import axios from 'axios'
import {baseUrl} from '../../../config'
import SEO from '../../commons/SEO'
import LoanBox from './LoanBox'
import LiquidityText from './LiquidityText'
import FAQs from '../assistenza/FAQs'
import H2 from '../../commons/H2'

const FormText = () => {
  return (
    <>
      <StyledH1>CON <Em>QuintoPuoi</Em> REALIZZARE I TUOI PROGETTI</StyledH1>
      <StyledP>Scopri la nostra cessione del quinto. Richiedi qui il tuo prestito!</StyledP>
    </>
  )
}

const seoTitle = 'QuintoPuoi: cessione del quinto e anticipo tfs | QuintoPuoi'
const seoDescription = 'Scopri i nostri prestiti QuintoPuoi per ottenere immediatamente la tua liquidità. Richiedi subito un preventivo gratuito.'

// region Helpers
// Funzione per triggerare l'evento di conversione Google
const handleHomePageGoogleConversion = () => {
  console.log('Handling home page conversion')
  //@ts-ignore
  const gtag = window['gtag']
  gtag && gtag('event', 'conversion_event_page_view', {
    // <event_parameters>
  })
}
// endregion

const Home = () => {
  const [financingExamplesData, setFinancingExamplesData] = useState([])

  const isMobile = useMediaQuery({ query: '(max-width: 575px)' })

  const claim: string =
    "QuintoPuoi è un prodotto di Banca Sistema S.p.A.. Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del prodotto QuintoPuoi. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..";

  // Funzione per recuperare da backend il testo da mostrare nelle 3 card
  const retrieveFinancingExampleData = async (): Promise<any> => {
    return await axios.get(`${baseUrl}/financing-examples`)
      .then(res => {
        return res.data.payload
      })
      .catch(e => {
        console.log(e)
      })
  }

  const location = useLocation()

  useEffect(() => {
    handleHomePageGoogleConversion()
    retrieveFinancingExampleData()
      .then((data: any) => {
        setFinancingExamplesData(data)
      })
      .catch((e: any) => console.log(e.message))
  }, [])

  useEffect(() => {
    // Gestisce le ancore puntate da link in altre pagine (ad esempio dal componente ExampleText nella pagina /quinto-puoi)
    if(location && location.hash) {
      const elementId = location.hash.replace('#', '')
      document.getElementById(elementId)?.scrollIntoView()
    }
  }, [location])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ backgroundColor: "#fff" }}>
          <Header />
          <TFSContainer>
            <PPLink>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                <img
                  src={iconTfs}
                  alt="Wallet Icon"
                  style={{ maxWidth: "100%" }}
                />
                <h2 style={{ fontWeight: "bolder", marginLeft: "8px" }}>
                  Scopri come ricevere subito il tuo prestito personale
                </h2>
              </div>
              <div
                style={{
                  backgroundColor: "222",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "10px",
                  width: "20%",
                }}
              >
                <Link to="/prestitopuoi">
                  <img src={arrowSvg} alt="Arrow SVG" />
                </Link>
              </div>
            </PPLink>
          </TFSContainer>
        </div>
        <Hero/>
        <Form windowOffset={900}>
          <FormText/>
        </Form>
        <VideoPlayer/>
        <IntroDocument/>
        {/*div che serve solo come ancora per gli esempi di finanziamento*/}
        <div id={'qp-loan-examples'}/>
        {isMobile ? <ExamplesSlider data={financingExamplesData}/> : <Examples data={financingExamplesData}/>}
        <ClaimContainer>
          <p></p>
          <p>{claim}</p>
          <p></p>
        </ClaimContainer>
        <LoanBox/>
        <LiquidityText/>
        <TfsBox/>
        <BSJumbo/>
        <FAQsContainer>
          <StyledH2>
            FAQ
          </StyledH2>
          <FAQs/>
        </FAQsContainer>
        <Footer/>
        <nav>{/* <Link to="/about">About</Link> */}</nav>
      </div>
    </>
  )
};

export default Home

// region Style
const TFSContainer = styled.div`
  max-width: 1140px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  background-color: #fff;
  padding: 10px;
`
const PPLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border: #fcbb00 3px solid;
  border-left: none;
  border-right: none;
  width: 100%;
  padding: 5px;
  margin: 53px 0 15px 0;
  letter-spacing: 1px;
`
const ClaimContainer = styled.div`
  font-size: 1rem;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin-top: 50px;
`
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const StyledH2 = styled(H2)`
  text-align: center;
`
const FAQsContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  padding: 3rem 0;
`
const StyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
// endregion
