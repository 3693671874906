import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ChevronDown from '../../images/ico_chevron_down.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

interface SelectProps {
  name: string
  options: Options[]
  placeHolderText: string
  icon?: string
  dataFromSelect?: any
  isTextBold?: boolean
  required?: boolean
  selectContainerBackground?: string
}

export interface Options {
  value: string
  label: string
}

export interface ISelectData {
  name: string
  value: string
  category: string
}

const Select: React.FC<SelectProps> = ({ name, options, placeHolderText, icon, dataFromSelect, isTextBold = false, required = false, selectContainerBackground }) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState<boolean>(false)

  const dataFromLocalStorage: ISelectData = JSON.parse(localStorage.getItem('selectData') || '[]').find(
    (o: any) => o.category === name
  )

  const [selectedData, setSelectedData] = useState(
    dataFromLocalStorage || {
      name: '',
      value: '',
      category: ''
    }
  )

  const ref = useRef<any>(null)

  // setta i campi dalla select quando si clicca su una delle opzioni
  const handleSelectChange = (opt: Options) => {
    const newValue = { name: opt.label, value: opt.value, category: name }
    dataFromSelect && dataFromSelect(newValue)

    setSelectedData(newValue)

    let localStorageData = JSON.parse(localStorage.getItem('selectData') || '[]')
    const previousFieldIndex = localStorageData.findIndex((elem: any) => elem.category === name)
    if(previousFieldIndex > -1) {
      localStorageData[previousFieldIndex] = newValue
      localStorage.setItem('selectData', JSON.stringify(localStorageData))
    } else {
      localStorageData = [...localStorageData, newValue]
      localStorage.setItem('selectData', JSON.stringify(localStorageData))
    }

    setAreOptionsOpen(false)
  }

  // elimina i campi dalla select quando si clicca sulla x
  const handleDeleteField: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    dataFromSelect && dataFromSelect({ name: '', value: '', category: selectedData.category })
    setSelectedData({ name: '', value: '', category: selectedData.category })
  }

  // gestisce l'apertura e la chiusura del menu a tendina
  const handleChange = () => {
    setAreOptionsOpen(!areOptionsOpen)
  }

  // gestisce l'apertura e la chiusura del menu a tendin
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && areOptionsOpen && !ref.current.contains(event.target)) setAreOptionsOpen(false)
  }

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  // Richiamo dataFromSelect() per impostare il primo valore nel caso in cui l'utente non abbia ancora interagito con la select
  useEffect(() => {
    dataFromSelect(selectedData)
  }, [])

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <SelectContainer onClick={handleChange} open={areOptionsOpen} backgroundColor={selectContainerBackground}>
        {icon ? (
          <div
            style={{
              backgroundImage: `url(${icon})`,
              backgroundAttachment: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '80px',
              marginLeft: 2
            }}
          />
        ) : <Filler/>}
        <SelectedValue isTextBold={isTextBold}>
          {/*select che serve solo a far comparire il messaggio di errore nativo nel caso in cui sia un campo obbligatorio*/}
          {required &&
            <FakeSelect required={true} value={selectedData.value} onChange={(e) => {
              e.preventDefault();
              console.log('Fake select value change', e.target.value)
            }}>
              <option key={-1} value={''}/>
              {options.map((option, index) =>
                <option key={index} value={option.value}>{option.label}</option>
              )}
            </FakeSelect>
          }
          <SelectedValueLabel>{selectedData.name !== '' ? selectedData.name : placeHolderText}</SelectedValueLabel>
          {selectedData.name && (
            <FontAwesomeIcon
              onClick={handleDeleteField}
              icon={faClose}
              style={{
                placeSelf: 'center',
                fontWeight: '200',
                fontSize: '15px',
                color: '#000',
                cursor: 'pointer'
              }}
            />
          )}
        </SelectedValue>
        <div
          style={{
            backgroundImage: `url(${ChevronDown})`,
            backgroundAttachment: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            width: '60px',
            height: '100%',
            transform: areOptionsOpen ? 'rotate(180deg)' : '',
            transition: 'transform 0.2s'
          }}
        />
        <OptionsWrapper onClick={(e) => e.stopPropagation()}>
          {areOptionsOpen && (
            <DropDownList>
              {options.map((opt, index) => (
                <OptionLi
                  key={index}
                  onClick={() => {
                    handleSelectChange(opt)
                  }}>
                  {opt.label}&nbsp;
                </OptionLi>
              ))}
            </DropDownList>
          )}
        </OptionsWrapper>
      </SelectContainer>
    </div>
  )
}

export default Select

// region Style
const Filler = styled.div`
  width: 20px;
`
const SelectContainer = styled.div<{ open: boolean, backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({backgroundColor}) => (backgroundColor ? backgroundColor : '#fff')};
  height: 50px;
  padding: 0;
  margin: 0 5px;
  width: 100%;
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #222;
  font-size: 1.3rem;
  border-radius: ${(props) => (props.open ? '30px 30px 0 0' : '30px')};
  transition: border-radius 0.3s;
  position: relative;
  cursor: pointer;
`
const OptionsWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  border: none;
  overflow: hidden;
  box-shadow: 0 3px 18px 0 rgb(45 46 46 / 15%);
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0 0 30px 30px;
`
const DropDownList = styled.ul`
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  color: #222;
  border-radius: 0 0 30px 30px;
  margin: 0;
  padding: 0;
  max-height: 240px;

  & > li {
    cursor: pointer;
    text-align: left;
  }
  & > li:last-child {
    border-radius: 0 0 30px 30px;
  }
`
const SelectedValue = styled.div<any>`
  text-align: initial;
  display: grid;
  grid-template-columns: 1fr auto;
  place-content: center;
  width: 100%;
  letter-spacing: -0.5px;
  ${props => props.isTextBold ? 'font-weight: bold' : ''};
`
const SelectedValueLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 1.3rem;
`
const OptionLi = styled.li`
  display: list-item;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  list-style: none;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: 400;
  width: 100%;
  &:hover {
    background-color: #fcbb00;
    color: #ffffff;
  }
`
const FakeSelect = styled.select`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  top: 40px;
  left: 25px;
`
// endregion
