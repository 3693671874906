import { DeleteOutlineRounded } from '@mui/icons-material'
import { IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import { DragDropContext, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'


export const FileDropBox: React.FC<FileDropBoxProps> = (props) => {
  const {
    files,
    id,
    title = '',
    setFiles,
    fileNames,
    setFileNames,
    reorder = false,
    render = ({ filename }) => (
      <FileInfo>
        <div>{filename ? filename.slice(0, filename.lastIndexOf('.')) : ''}</div>
      </FileInfo>
    )
  } = props

  const onDrop = (file: File[]) => {
    if (file && file.length > 0) {
      const newFiles = Object.assign(files, {})
      const newOrder = [...fileNames]
      file.forEach((f) => {
        const name = f.name.slice(0, f.name.lastIndexOf('.'))
        //if (!name.match(/^[A-Za-z0-9-_]+$/g)) return
        if (newOrder.find((fn) => fn === f.name)) return
        newFiles[`${f.name}`] = f
        newOrder.push(`${f.name}`)
      })
      setFileNames(newOrder)
      setFiles(newFiles)
    }
  }
  const removeFileFromList = (key: string) => {
    const newFiles = Object.assign(files, {})
    const newOrder = [...fileNames].filter((o) => o !== key)
    delete newFiles[key]
    setFiles(newFiles)
    setFileNames(newOrder)
  }

  const onDragEnd = (result: DropResult) => {
    if (!reorder) return
    const { destination, source, draggableId } = result

    if (!destination) return

    if (destination.index === source.index) return

    //creazione nuovo ordine
    const newOrder = [...fileNames]
    newOrder.splice(source.index, 1)
    newOrder.splice(destination.index, 0, draggableId)
    setFileNames(newOrder)
  }

  const renderFileInfoComponent = (key: string, provided?: DraggableProvided) => {
    return (
      <FileInfoContainer
        key={key}
        reorder={reorder}
        {...provided?.draggableProps}
        ref={provided?.innerRef}>
        <>
          <IconButton onClick={() => removeFileFromList(key)} style={{ placeSelf: 'center' }}>
            <DeleteOutlineRounded sx={{ color: 'error.main' }} />
          </IconButton>
          {render({ filename: key, file: files[key] })}
        </>
      </FileInfoContainer>
    )
  }

  return (
    <MohFilesContainer>
      <TitleBox>
        <Typography variant={'subtitle2'} sx={{ color: 'primary.dark' }}>
          {title}
        </Typography>
      </TitleBox>
      <CardContainer>
        <Dropzone
          onDrop={(recordFile: any) => onDrop(recordFile)}
          accept={validFileTypes}
          maxSize={10 * 1000000}
          >
          {({ getRootProps, getInputProps }: any) => (
            <DragAndDrop>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Clicca o trascina qui gli allegati</p>
              </div>
            </DragAndDrop>
          )}
        </Dropzone>
      </CardContainer>
      <CardContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id} key={id}>
            {(provided: any) => (
              <PerfectScrollbar style={{ width: '100%' }}>
                <FileList ref={provided.innerRef} {...provided.droppableProps}>
                  {fileNames.length > 0 &&
                    fileNames.map((o, index) => renderFileInfoComponent(o))}
                  {provided.placeholder}
                </FileList>
              </PerfectScrollbar>
            )}
          </Droppable>
        </DragDropContext>
      </CardContainer>
    </MohFilesContainer>
  )
}

// region types
interface FilesObject {
  [key: string]: File | null
}

interface FileDropBoxProps {
  files: FilesObject
  setFiles: (files: FilesObject) => void
  id: string
  title?: string
  fileNames: string[]
  setFileNames: (newOrder: string[]) => void
  render?: ({ filename, file }: { filename: string; file: File | null }) => React.FC
  reorder?: boolean
  fileType?: string
}
const validFileTypes = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg', '.jpg'],
}
// endregion

//region Style
const CardContainer = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`
const DragAndDrop = styled(Paper)`
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #bbbbbb;
  text-align: -webkit-center;
  min-height: 5rem;
  min-width: 100%;
`
const FileList = styled.div`
  overflow: auto;
  width: 100%;
`
const MohFilesContainer = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr;
`

const FileInfoContainer = styled.div<{ reorder: boolean }>`
  display: grid;
  grid-template-columns: ${({ reorder }) => (reorder ? 'max-content' : '')} max-content 3fr;
  max-height: 5rem;
  margin: 0.5rem 1rem 0 0;
  gap: 2rem;
`
const FileInfo = styled.div`
  align-self: center;
  opacity: 0.7;
  display: grid;
  gap: 1rem;
  grid-template-columns: max-content;
  & > div {
    place-self: start center;
  }
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
// endregion
