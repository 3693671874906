import React, {useState} from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import H4 from '../../commons/H4'
import Link from '../../commons/Link'
import PensionatiText from './PensionatiText'
import DipendentiPubbliciText from './DipendentiPubbliciText'
import DipendentiPrivatiText from './DipendentiPrivatiText'
import AdvantagesText from './AdvantagesText'
import ReferenceRegulationText from './ReferenceRegulationText'
import PayrollDeductionLoanText from './PayrollDeductionLoanText'
import HowDoesItWorkText from './HowDoesItWorkText'
import ExamplesText from './ExamplesText'
import pensionatoIcon from '../../../img/pensionato_bl_2x.png'
import stataleIcon from '../../../img/pubblico_bl_2x.png'
import privatoIcon from '../../../img/privati_bl_2x.png'
import {useNavigate} from 'react-router-dom'

const TripleTab = () => {
  const [tabToShow, setTabToShow] = useState(1)
  const navigate = useNavigate()

  return (
    <>
      <Container>
        <Row>
          <Title>
            <H2>SCOPRI IL SERVIZIO CHE FA PER TE IN BASE AL TUO PROFILO</H2>
          </Title>
        </Row>
        <IconsContainer>
          <IconsInnerContainer>
            <IconsRow>
              <SwitchContainer>
                <Switch onClick={() => setTabToShow(1)} isSelected={tabToShow === 1}>
                  <ImgContainer>
                    <Img src={pensionatoIcon}/>
                  </ImgContainer>
                  <H4>SOLUZIONI PER<Span isSelected={tabToShow === 1}>PENSIONATO</Span></H4>
                </Switch>
              </SwitchContainer>
              <SwitchContainer>
                <Switch onClick={() => setTabToShow(2)} isSelected={tabToShow === 2}>
                  <ImgContainer>
                    <Img src={stataleIcon}/>
                  </ImgContainer>
                  <H4>SOLUZIONI PER<Span isSelected={tabToShow === 2}>DIPENDENTE STATALE / PUBBLICO</Span></H4>
                </Switch>
              </SwitchContainer>
              <SwitchContainer>
                <Switch onClick={() => setTabToShow(3)} isSelected={tabToShow === 3}>
                  <ImgContainer>
                    <Img src={privatoIcon}/>
                  </ImgContainer>
                  <H4>SOLUZIONI PER<Span isSelected={tabToShow === 3}>DIPENDENTE DI SOCIETÀ SPA/SRL</Span></H4>
                </Switch>
              </SwitchContainer>
            </IconsRow>
          </IconsInnerContainer>
        </IconsContainer>
      </Container>
      <Article>
        <AdvantagesContainer>
          <AdvantagesRow>
            <Advantages>
              <div>
                <Tab isActive={tabToShow === 1}>
                  <ReferenceRegulationText/>
                  <PayrollDeductionLoanText/>
                  <HowDoesItWorkText/>
                  <ExamplesText/>
                  <AdvantagesText/>
                  <PensionatiText/>
                </Tab>
                <Tab isActive={tabToShow === 2}>
                  <ReferenceRegulationText/>
                  <PayrollDeductionLoanText/>
                  <HowDoesItWorkText/>
                  <ExamplesText/>
                  <AdvantagesText/>
                  <DipendentiPubbliciText/>
                </Tab>
                <Tab isActive={tabToShow === 3}>
                  <ReferenceRegulationText/>
                  <PayrollDeductionLoanText/>
                  <HowDoesItWorkText/>
                  <ExamplesText/>
                  <AdvantagesText/>
                  <DipendentiPrivatiText/>
                </Tab>
              </div>
            </Advantages>
            <LinkContainer>
              <Link text={'CHIEDI SUBITO UN PREVENTIVO GRATUITO'} onClick={() => navigate('/preventivatore')}/>
            </LinkContainer>
          </AdvantagesRow>
        </AdvantagesContainer>
      </Article>
    </>
  )
}

// region Style
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  padding-top: 3rem !important;
  margin-top: 3rem !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Title = styled.div`
  text-align: center !important;
  flex: 0 0 100%;
  max-width: 100%;
`
const IconsContainer = styled.div`
  padding-top: 1rem !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const IconsInnerContainer = styled.div`
  @media (min-width: 768px){
    margin-left: 8.33333%;
  }
  @media (min-width: 768px){
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const IconsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SwitchContainer = styled.div`
  @media (min-width: 768px){
    margin-bottom: 0 !important;
  }
  margin-bottom: 3rem !important;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Switch = styled.div<any>`
  :hover img {
    transform: scale(1.1); 
  }
  ${props => props.isSelected ? 'border-bottom: 5px solid #FCBB00' : ''};
  text-align: center;
  display: block;
  padding-bottom: 2rem;
  margin-bottom: -3px;
  text-decoration: none;
  @media (min-width: 768px) {
    padding-bottom: 1.5rem !important;
  }
  padding-bottom: 0 !important;
  cursor: pointer;
`
const ImgContainer = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  max-width: 15.8rem;
`
const Img = styled.img`
  transition: all .3s ease-in-out;
  border-style: none;
  max-width: 100%;
`
const Span = styled.span<any>`
  ${props => props.isSelected ? 'color: #FCBB00' : ''};
  display: block;
`
const Article = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  background-position: center center;
  background-size: cover;
  background-color: #fff;
  padding-bottom: 3rem;
  display: block;
  margin-bottom: 5rem;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const AdvantagesContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const AdvantagesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Advantages = styled.div`
  @media (min-width: 768px) {
    margin-left: 8.33333%;
  }
  @media (min-width: 768px){
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  padding-bottom: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Tab = styled.div<any>`
  text-align: initial;
  ${props => props.isActive ? 'display: block' : 'display: none'};
  @media (prefers-reduced-motion: reduce){
   transition: none;
  }
  transition: opacity 0.15s linear;
`
const LinkContainer = styled.div`
  text-align: center !important;
  flex: 0 0 100%;
  max-width: 100%;
`
// endregion

export default TripleTab
