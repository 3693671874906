import React from 'react'
import styled from 'styled-components'
import H3 from '../commons/H3'
import firstIcon from '../../img/cl_veloce.svg'
import secondIcon from '../../img/cl_semplice.svg'
import thirdIcon from '../../img/cl_sicuro.svg'

const LandingIconsRow = () => {
  return (
    <ColumnContainer>
      <ItemContainer>
        <IconContainer>
          <Icon src={firstIcon}/>
        </IconContainer>
        <StyledH3>Veloce</StyledH3>
      </ItemContainer>
      <ItemContainer>
        <IconContainer>
          <Icon src={secondIcon}/>
        </IconContainer>
        <StyledH3>Semplice</StyledH3>
      </ItemContainer>
      <ItemContainer>
        <IconContainer>
          <Icon src={thirdIcon}/>
        </IconContainer>
        <StyledH3>Sicuro</StyledH3>
      </ItemContainer>
    </ColumnContainer>
  )
}

// region Style
const ColumnContainer = styled.div`
  @media (min-width: 993px){
    padding-top: 1rem;
  }
  @media (min-width: 992px) {
    order: 1;
  }
  @media (min-width: 992px){
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  padding-top: 3rem;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const ItemContainer = styled.div`
  @media (min-width: 993px){
    width: 100%;
    display: block;
  }
  width: 33%;
  float: left;
`
const IconContainer = styled.div`
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 11%;
    left: 11%;
    width: 78%;
    height: 78%;
    box-shadow: 0 0 26px rgb(0 0 0 / 40%);
    border-radius: 50%;
    z-index: 1;
  }
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  border-radius: 50%;
  position: relative;
  margin: 0 1rem;
`
const Icon = styled.img`
  display: block;
  position: relative;
  z-index: 10;
  margin-bottom: 10px;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-style: none;
`
const StyledH3 = styled(H3)`
  text-transform: uppercase;
  text-align: center;
`
// endregion

export default LandingIconsRow
