import React from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import TitleSection from '../../commons/TitleSection'
import P from '../../commons/P'
import H2 from '../../commons/H2'
import mainBackground from '../../../img/bg_intro.jpg'
import headerBackground from '../../../img/header-neutra_scopri.jpg'
import mobileBackground from '../../../img/scopri_mobile-2.jpg'
import checkIcon from '../../../img/ico_check_brand.svg'
import TripleTab from './TripleTab'
import Form from '../../commons/Form'
import H1 from '../../commons/H1'
import SEO from '../../commons/SEO'
import FAQs from './FAQs'

const Subtitle = () => {
  return (
    <span style={{marginBottom: 0}} className="s1">
      <b> Il prestito semplice, veloce e sicuro<br/>
      dedicato ai lavoratori dipendenti e ai pensionati </b>
    </span>
  )
}
const FormText = () => {
  return (
    <>
      <StyledH1>Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em></StyledH1>
      <StyledP>Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo
        indeterminato da aziende SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici</StyledP>
    </>
  )
}
const seoTitle = 'Cessione del quinto per dipendenti e pensionati | QuintoPuoi'
const seoDescription = "Cessione del quinto: scopri cos'è la cessione del quinto, come funziona e come richiedere un preventivo online gratuito calcolando senza impegno la tua rata."

const QuintoPuoi = () => {

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <Header/>
      <TitleSection backgroundUrl={`url(${headerBackground})`} mobileBackgroundUrl={`url(${mobileBackground})`} title={'QuintoPuoi'}>
        <Subtitle/>
      </TitleSection>
      <Main>
        <Form windowOffset={300}>
          <FormText></FormText>
        </Form>
        <TripleTab/>
        <AdvantagesContainer>
          <Container>
            <Row>
              <RowElement>
                <H2>TRASFORMIAMO IL TUO QUINTO IN UN FINANZIAMENTO SU MISURA PER TE</H2>
                <P>Banca Sistema eroga il servizio finanziario <strong><Em>QuintoPuoi</Em></strong>, il prestito per i dipendenti pubblici e privati e per i pensionati, rimborsabile attraverso la <strong>cessione del quinto</strong> dello stipendio o della pensione.
                </P>
                <P>La restituzione della somma ottenuta avviene attraverso <strong>rate mensili comodamente trattenute dal cedolino dello stipendio o della pensione</strong>. L’importo delle rate mensili <strong>non può superare 1/5</strong> dello stipendio o della pensione ed il sottoscrittore della cessione del quinto può scegliere la <strong>durata del prestito</strong> che può essere di <strong>24, 36, 46, 60, 72, 84, 96, 108, 120 mesi</strong>.</P>
                <P>Il finanziamento è assistito per legge da coperture assicurative a garanzia del rischio vita e impiego.</P>
                <P>Richiedi un preventivo gratuito e calcola senza impegno la tua rata, inserendo la tua richiesta <strong>online</strong> o rivolgendoti <strong>al team <Em>QuintoPuoi</Em></strong> di Banca Sistema per un <strong>supporto specializzato</strong>. Siamo presenti <strong>sul territorio nazionale</strong> con le nostre sedi, filiali, sportelli ed una rete di agenzie.</P>
                <P style={{marginBottom: 0}}>Se avessi necessità di una ‘doppia cessione del quinto’, il team <strong><Em>QuintoPuoi</Em></strong> potrà supportarti nella valutazione della <strong>delegazione di pagamento</strong>, che potrebbe consentirti di ottenere un nuovo finanziamento cedendo un ulteriore quinto dello stipendio o della pensione.</P>
              </RowElement>
              <RowElement>
                <H2>VANTAGGI</H2>
                <Ul>
                  <Li><strong>LA PROFESSIONALITÀ DI BANCA SISTEMA</strong></Li>
                  <Li><strong>SEMPLICITÀ NELLA COMPILAZIONE DELLA RICHIESTA</strong></Li>
                  <Li><strong>RAPIDITÀ E COMODITÀ NELL’EROGAZIONE DEL PRESTITO</strong></Li>
                  <Li><strong>FINANZIAMENTO SICURO PERCHÉ PROTETTO DA POLIZZA ASSICURATIVA</strong></Li>
                </Ul>
              </RowElement>
            </Row>
          </Container>
        </AdvantagesContainer>
        <FAQs/>
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const AdvantagesContainer = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const Container = styled.div`
  text-align: initial;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  padding-top: 3rem !important;
  margin-top: 3rem !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const RowElement = styled.div`
  margin-bottom: 3rem !important;
  @media (min-width: 768px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
const Ul = styled.ul`
  padding: 0;
  list-style: none;
`
const Li = styled.li`
  color: #AEAEAE;
  padding: 0.5rem 0 0.5rem 4.4rem;
  background-image: url(${checkIcon});
  background-position: left center;
  background-repeat: no-repeat;
`
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const StyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
// endregion

export default QuintoPuoi
