import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import {useNavigate} from 'react-router-dom'

const LinkToTrasparenza = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <StyledP>
        Per ulteriori informazioni vi chiediamo di far riferimento alla sezione Trasparenza del nostro sito QuintoPuoi.it <StyledSpan onClick={() => navigate('/trasparenza')}>quintopuoi.it/trasparenza</StyledSpan> e alla sezione Trasparenza del sito corporate di Banca Sistema S.p.A. <StyledA href={'https://bancasistema.it/trasparenza'} target={'_blank'}>bancasistema.it/trasparenza</StyledA>
      </StyledP>
    </Container>
  )
}

export default LinkToTrasparenza

// region Style
const Container = styled.div`
  margin: 0 auto 10rem auto;
  max-width: 1095px;
`
const StyledP = styled(P)`
  text-align: left;
`
const StyledA = styled.a`
  cursor: pointer;
  color: #FCBB00;
`
const StyledSpan = styled.a`
  cursor: pointer;
  color: #FCBB00;
  text-decoration: underline;
`
// endregion
