import React from "react"
import styled from "styled-components"
import Header from "../../commons/Header"
import Footer from "../../commons/footer/Footer"
import TitleSection from "../../commons/TitleSection"
import Link from "../../commons/Link"
import H2 from "../../commons/H2"
import H4 from "../../commons/H4"
import P from "../../commons/P"
import mainBackground from "../../../img/bg_intro.jpg"
import headerBackground from "../../../img/firma__top.jpg"
import { useNavigate } from "react-router-dom"
import Form from "../../commons/Form"
import H1 from '../../commons/H1'
import FAQs from '../assistenza/FAQs'

const Subtitle = () => {
  return(
    <b style={{fontSize: 'inherit'}}>
      Apri la cessione del quinto comodamente da casa tua,
      <br />
      da oggi anche con SPID e CIE!
    </b>
  )
}
const FormText = () => {
  return (
    <>
      <StyledH1>Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em></StyledH1>
      <FormStyledP>Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo
        indeterminato da aziende SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici</FormStyledP>
    </>
  )
}

const FirmaDigitale = () => {
  const navigate = useNavigate()

  return (
    <>
      <Header />
      <TitleSection
        backgroundUrl={`url(${headerBackground})`}
        title={"LA FIRMA DIGITALE"}
      >
        <Subtitle />
      </TitleSection>
      <Main>
        <Form windowOffset={300}>
          <FormText/>
        </Form>
        <Wrapper>
          <Container>
            <Row>
              <VideoTitleContainer>
                <StyledH2>
                  Guarda il video e scopri quanto è semplice sottoscrivere il
                  tuo finanziamento con la firma digitale
                </StyledH2>
              </VideoTitleContainer>
            </Row>
            <Row>
              <VideoWrapper>
                <VideoContainer>
                  <IFrame
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/09ra4UluNTg"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                    frameBorder={0}
                    allowFullScreen={true}
                  ></IFrame>
                </VideoContainer>
              </VideoWrapper>
            </Row>
            <Row>
              <QuoteLinkContainer>
                <Link
                  text={"CHIEDI SUBITO UN PREVENTIVO GRATUITO"}
                  onClick={() => navigate("/preventivatore")}
                />
              </QuoteLinkContainer>
            </Row>
            <Row>
              <TextWrapper>
                <TextContainer>
                  <StyledP>
                    Banca Sistema, specializzata nella{" "}
                    <strong>cessione del quinto</strong> con il brand{" "}
                    <strong>
                      <Em>QuintoPuoi</Em>
                    </strong>
                    , ti offre l’opportunità di richiedere un finanziamento
                    tutto online senza recarti in filiale e senza dover
                    incontrare il commerciale di zona.
                  </StyledP>
                  <StyledP>
                    Questa innovazione, nata all’interno del processo di
                    digitalizzazione della banca e accelerata dall’emergenza
                    sanitaria Covid19, mira a tutelare te e tutte le persone
                    coinvolte nel processo di autorizzazione del finanziamento,
                    consentendoti di gestire tutta la procedura di
                    autenticazione online comodamente da casa tua.
                  </StyledP>
                  <StyledP>
                    Dovrai semplicemente seguire le istruzioni che il team{" "}
                    <strong>
                      <Em>QuintoPuoi</Em>
                    </strong>{" "}
                    ti invierà via mail insieme al link da cliccare per accedere
                    al portale online.
                    <strong>
                      {" "}
                      Sarà facile e immediato sottoscrivere da remoto tutta la
                      documentazione necessaria all’attivazione del tuo
                      finanziamento!
                    </strong>
                  </StyledP>
                  <StyledP>&nbsp;</StyledP>
                  <StyledP>
                    Un consulente{" "}
                    <strong>
                      <Em>QuintoPuoi</Em>
                    </strong>{" "}
                    ti supporterà in tutte le fasi di gestione della pratica
                    online, che possiamo riassumere in:
                  </StyledP>
                </TextContainer>
              </TextWrapper>
            </Row>
            <Row>
              <ListElementContainer>
                <StyledH4>1) FASE PRELIMINARE</StyledH4>
                <Strong>Sottoscrizione dei consensi privacy</Strong>
                <SubTextContainer>
                  <StyledP style={{ marginBottom: 0 }}>
                    Riceverai sul cellulare un SMS con codice OTP (One Time
                    Password) da inserire nella piattaforma online per
                    sottoscrivere i consensi privacy. Successivamente riceverai
                    un preventivo nominale
                  </StyledP>
                </SubTextContainer>
              </ListElementContainer>
              <ListElementContainer>
                <StyledH4>2) FASE ISTRUTTORIA</StyledH4>
                <Strong>Video-riconoscimento</Strong>
                <SubTextContainer>
                  <StyledP style={{ marginBottom: 0 }}>
                    Verrai quindi guidato sulla piattaforma ai fini della tua
                    identificazione attraverso un sistema di
                    video-riconoscimento a distanza, oppure tramite i tuoi
                    accessi personali SPID o eventualmente tramite CIE (carta
                    identità elettronica).
                  </StyledP>
                </SubTextContainer>
              </ListElementContainer>
              <ListElementContainer>
                <StyledH4>3) FASE EMISSIONI DOCUMENTI</StyledH4>
                <Strong>Sottoscrizione contratto</Strong>
                <SubTextContainer>
                  <StyledP style={{ marginBottom: 0 }}>
                    Potrai accedere alla piattaforma per l’ultima volta per
                    autorizzare la proposta contrattuale con la tua firma
                    digitale e quindi finalizzare la tua richiesta di
                    finanziamento
                  </StyledP>
                </SubTextContainer>
              </ListElementContainer>
            </Row>
            <Row>
              <DescriptionContainer>
                <DescriptionWrapper>
                  <StyledP>
                    <strong>
                      Per accedere al servizio è sufficiente disporre di uno
                      smartphone o di un pc comprensivo di videocamera e
                      microfono
                    </strong>
                    . Per accedere allo SPID saranno inoltre necessarie le tue
                    credenziali personali mentre per utilizzare la Carta
                    d’identità elettronica (CIE) dovrai avere con te il Pin e un
                    lettore di smart card. La piattaforma è agile e intuitiva.
                  </StyledP>
                  <StyledP>
                    Accedi anche tu in modo semplice e sicuro alla liquidità che
                    cerchi attraverso la piattaforma digitale{" "}
                    <strong>
                      <Em>QuintoPuoi</Em>.
                    </strong>
                  </StyledP>
                  <StyledP>&nbsp;</StyledP>
                  <StyledP>Banca Sistema è sempre più vicina a te!</StyledP>
                  <StyledP style={{ marginBottom: 0 }}>
                    <Small>
                      Messaggio con finalità promozionali. QuintoPuoi è un
                      prodotto di Banca Sistema S.p.A.. Per informazioni
                      economiche e contrattuali fare riferimento alle
                      “Informazioni Europee di Base sul Credito ai Consumatori”
                      (SECCI). La concessione del finanziamento è subordinata
                      all’approvazione di Banca Sistema S.p.A.
                    </Small>
                  </StyledP>
                </DescriptionWrapper>
              </DescriptionContainer>
            </Row>
          </Container>
        </Wrapper>
      </Main>
      <FAQsContainer>
        <H2 style={{textAlign: 'center'}}>
          FAQ
        </H2>
        <FAQs/>
      </FAQsContainer>
      <Footer />
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const FAQsContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  padding: 3rem 0;
`
const Wrapper = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  position: relative;
  background-image: url(${mainBackground});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`;
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
const VideoTitleContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const StyledH2 = styled(H2)`
  @media (min-width: 992px) {
    font-size: 40px;
    line-height: 45px;
  }
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -1px;
  text-align: center;
  text-transform: uppercase;
  margin: 60px 0;
`;
const VideoWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-top: 56.25%;
  display: block;
  content: "";
  box-sizing: inherit;
`;
const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
`;
const QuoteLinkContainer = styled.div`
  margin: 64px 0 48px;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const TextWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const TextContainer = styled.div`
  text-align: center;
  margin: 0 0 48px 0;
`;
const StyledP = styled(P)``;
const ListElementContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const StyledH4 = styled(H4)`
  text-align: initial;
  color: #fcbb00;
  margin: 0 0 32px 0;
`;
const Em = styled.em`
  font-style: normal;
  color: #fcbb00;
`;
const SubTextContainer = styled.div`
  text-align: initial;
`;
const Strong = styled.strong`
  text-align: initial;
  margin: 0 0 32px 0;
  display: block;
`;
const DescriptionContainer = styled.div`
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const DescriptionWrapper = styled.div`
  margin: 48px 0 48px 0;
`;
const Small = styled.small`
  font-size: 1.2rem;
`;
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const FormStyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
// endregion

export default FirmaDigitale;
