import React from 'react'
import styled from 'styled-components'
import H1 from '../../commons/H1'
import H2 from '../../commons/H2'
import Link from '../../commons/Link'
import { useNavigate } from 'react-router-dom'

interface ExamplesCardProps {
  title: string
  amount: string
  amountPerMonth: number
  installments: number
  tan: number
  taeg: number | string
  classHover?: string
}

const ExamplesCard: React.FC<ExamplesCardProps> = ({
  title,
  amount,
  amountPerMonth,
  installments,
  tan,
  taeg,
  classHover
}) => {
  const navigate = useNavigate()

  return (
    <div className={`${classHover}`}>
      <Card>
        <Title>{title}</Title>
        <Amount>
          <span style={{ fontSize: '1.3rem' }}>Ottieni</span> <br></br> {amount}
        </Amount>
        <AmountPerMonth className="amount-month">
          <div>
            <small>con</small>
            <p className="amount-text" style={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
              €{amountPerMonth}
            </p>
            <small>/mese</small>
          </div>
        </AmountPerMonth>
        <Installments>{installments}</Installments>
        <Tan>{tan}</Tan>
        <Taeg>{taeg}</Taeg>
        <LinkYellowButton
          className="examples-button"
          onClick={() => {
            navigate('/preventivatore')
          }}
          text={'Chiedi subito'}
          textStyle={{ textTransform: 'uppercase' }}
          linkStyle={{ marginTop: '3rem' }}/>
      </Card>
    </div>
  )
}

export default ExamplesCard

const Card = styled.div`
  transition: all 0.1s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 650px;
  background-color: #fff;
  padding: 5rem 3rem 5rem;

  &:hover {
    transform: scale(1.1);
    & @media (max-width: 768px) {
      pointer-events: none;
    }
  }
`

const LinkYellowButton = styled(Link)`
  margin-top: 2rem;
`

const Title = styled(H2)`
  color: #fcbb00;
  &:hover {
    color: #fff;
  }
`

const Amount = styled(H1)`
  text-transform: uppercase;
  color: #767474;
  transition: all 0.1s ease-out;
  font-size: 3rem;
`

const AmountPerMonth = styled.div`
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
  background-color: #fff;
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  & p {
    font-size: 1.8rem;
    display: block;
    width: 100%;
    font-weight: 400;
  }
  & small {
    vertical-align: inherit;
    font-size: 1.5rem;
  }
`

const Installments = styled.div`
  border-bottom: 1px dashed rgba(245, 245, 245, 0.4);
  font-size: 3rem;
  font-weight: 700;
  color: #aeaeae;
  transition: all 0.1s ease-out;
  &:hover {
    color: #fff;
  }
`

const Tan = styled.p`
  border-bottom: 1px dashed rgba(245, 245, 245, 0.4);
  color: #fff;
  font-weight: 700;
  color: #aeaeae;
  font-size: 3rem;
  transition: all 0.1s ease-out;
  &:hover {
    color: #fff;
  }
`

const Taeg = styled.p`
  border-bottom: 1px dashed rgba(245, 245, 245, 0.4);
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  color: #aeaeae;
  transition: all 0.1s ease-out;
  &:hover {
    color: #fff;
  }
`
