import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import H1 from '../../commons/H1'
import imgSource from '../../../img/ico_check_brand.svg'

const ThankYouSection = () => {
  return (
    <Section>
      <Container>
        <div>
          <Img src={imgSource}/>
          <StyledH1>GRAZIE!</StyledH1>
          <StyledP>
            <strong>Verrai ricontattato dai nostri operatori secondo le modalità da te selezionate.</strong>
          </StyledP>
        </div>
      </Container>
    </Section>
  )
}

// region Style
const Section = styled.div`
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 70%);
  padding-top: 1rem;
  margin-top: -16.4rem;
  min-height: 16rem;
  background-color: rgba(34, 34, 34, 0.85);
  position: relative;
  max-width: 114rem;
  margin: 0 auto;
  z-index: 30;
  margin-top: -8rem;
  border-radius: 4.5rem;
  display: block;
`
const Container = styled.div`
  background-color: #FFF;
  border-radius: 4.5rem;
  height: 44rem;
  padding: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  margin-bottom: 1.5rem;
  width: 5rem;
  border-style: none;
  max-width: 100%;
`
const StyledH1 = styled(H1)`
  margin-bottom: 0.5em;
`
const StyledP = styled(P)`
  color: #767474;
  margin-bottom: 0;
`
// endregion

export default ThankYouSection
