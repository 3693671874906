import React from 'react'
import styled from 'styled-components'
import loadingIcon from '../../img/loading.svg'

const Loading = () => {
  return (
    <Container>
      <Img src={loadingIcon} alt="Loading"/>
    </Container>
  )
}

// region Style
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  z-index: 1000;
`
const Img = styled.img`
  
`
// endregion

export default Loading
