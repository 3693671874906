import React from 'react'
import styled from 'styled-components'
import Collapsable from '../../commons/footer/Collapsable'
import Link from '../../commons/Link'
import {ReactComponent as Logo} from '../../../img/logo.svg'
import phoneIcon from '../../../img/phone_small.svg'
import {useNavigate} from 'react-router-dom'
import thanksLogo from '../../../images/banca_sistema_white_new.png'


const ThanksFooter = () => {
  const navigate = useNavigate()

  return (
    <Footer>
      <Container>
        <InnerContainer>
          <Row>
            <BSLogoContainer>
              <img src={thanksLogo} style={{ maxWidth: '100%', height: 'auto', borderStyle: 'none'}}/>
            </BSLogoContainer>
            <CompanyInfoContainer>
              <Title>
                SEDE LEGALE
              </Title>
              <Address>
                Largo Augusto 1A, angolo Via Verziere 13 - 20122 Milano<br/>
                <a href={'https://www.bancasistema.it'} target={'_blank'} style={{color: 'white'}}>www.bancasistema.it</a>
              </Address>
              <CompanyInfo>
                P.IVA 12870770158<br/>
                2011-2023 © Banca Sistema S.p.A.
              </CompanyInfo>
            </CompanyInfoContainer>
            <LogoContainer>
              <Logo style={{ maxWidth: '100%', height: 'auto', borderStyle: 'none'}}/>
            </LogoContainer>
            <ContactsContainer>
              <Title>NUMERO VERDE</Title>
              <Link link={'tel:800124344'} text={'800 124 344'} linkStyle={linkStyle}/>
              <TimingInfo>
                ATTIVO DAL LUNEDÌ AL VENERDÌ
                <br/>
                DALLE ORE 9.00 ALLE ORE 13.00
                <br />
                ASSISTENTE VIRTUALE 7/7 GIORNI 24H
              </TimingInfo>
            </ContactsContainer>
          </Row>
          <CollapsableContainer>
            <CollapsableInnerContainer>
              <Collapsable textStyle = {{fontWeight: 400, fontSize: '1.5rem', lineHeight: '1.5'}}/>
            </CollapsableInnerContainer>
          </CollapsableContainer>
          <LinksRow>
            <LinksContainer>
              <A onClick={() => navigate('/privacy')}>PRIVACY</A>
              <A onClick={() => navigate('/cookies')}>COOKIES</A>
              <A onClick={() => navigate('/trasparenza')}>TRASPARENZA</A>
              <A href="https://bancasistema.it/reclami">RECLAMI</A>
              <A onClick={() => navigate('/emergenza-covid-19')}>EMERGENZA COVID-19</A>
            </LinksContainer>
          </LinksRow>
        </InnerContainer>
      </Container>
    </Footer>
  )
}

// region Style
const linkStyle = {
  backgroundImage: `url(${phoneIcon})`,
  paddingLeft: '4rem',
  backgroundPosition: '1.6rem center',
  backgroundRepeat: 'no-repeat',
  minWidth: 'unset'
}

const Footer = styled.div`
  font-size: 1.2rem;
  margin-top: 3rem;
  display: block;
`
const Container = styled.div`
  background-color: #222;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  margin-top: 1rem;
`
const InnerContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const CollapsableContainer = styled.div`
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const CollapsableInnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const BSLogoContainer = styled.div`
display: -webkit-box;
  @media (min-width: 992px) {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const CompanyInfoContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  text-align: initial;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Title = styled.div`
  color: #FFB800;
  font-weight: 700;
  margin-bottom: 10px;
`
const Address = styled.div`
  color: #fff;
`
const CompanyInfo = styled.div`
  color: #CCCCCC;
  margin-top: 1rem;
`
const LogoContainer = styled.div`
  display: -webkit-box;
  @media (min-width: 992px) {
    order: 4;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const ContactsContainer = styled.div`
  @media (min-width: 992px) {
    order: 3;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  text-align: initial;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const TimingInfo = styled.div`
  color: #fff;
  margin-top: 1rem;
`
const LinksContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  padding: 20px;
  width: 100%;
`
const A = styled.a`
  cursor: pointer;
  display: inline-block;
  margin: 0 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
`
const LinksRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
//endregion

export default ThanksFooter
