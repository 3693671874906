import React from "react";
import styled from "styled-components";
import H3 from "../../commons/H3";
interface ListItemProps {
  img: string;
  title: string;
  text: string;
  link: string;
  blank?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  img,
  title,
  text,
  link,
  blank,
}) => {
  return (
    <ul>
      <Item>
        <a
          style={{
            textDecoration: "none",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
          }}
          href={link}
          {...(blank ? { target: "_blank" } : {})}
        >
          <img src={img} alt={title} style={{ marginRight: "2rem" }} />
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "#fff",
            }}
          >
            <H3 style={{ textTransform: "uppercase", textAlign: "left" }}>
              {title}
            </H3>
            <p
              style={{
                textAlign: "left",
                fontSize: "1.4rem",
                fontWeight: "700",
              }}
            >
              {text}
            </p>
          </div>
        </a>
      </Item>
    </ul>

    /*   <a
      style={{ textDecoration: "none", margin: "10px 0" }}
      href={link}
      {...(blank ? { target: "_blank" } : {})}
    >
      <ItemContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img} alt={title} />
        </div>
        <div
          style={{ fontWeight: "bold", textDecoration: "none", color: "#fff" }}
        >
          <H3 style={{ textTransform: "uppercase", textAlign: "left" }}>
            {title}
          </H3>
          <p style={{ textAlign: "left" }}>{text}</p>
        </div>
      </ItemContainer>
    </a> */
  );
};

export default ListItem;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const Item = styled.li`
  padding-bottom: 1.5rem;
  list-style: none;
`;
